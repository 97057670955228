html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

/* #region Colours */

.bg-primary {
  background-color: #1862b8 !important;
  color: white !important;
}

.bg-opacity-50.bg-primary {
  background-color: #1863b88a !important;
  color: white !important;
}

body {
  background-color: #ccd9e8;
}

/* #endregion */

/* #region Widths */

.w-90 {
  width: 90%;
}

/* #endregion */

.border-inset {
  border-style: inset solid !important;
}